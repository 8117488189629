<template>
  <b-row>
      <b-col lg="6">
        <MainForm
          type="edit"
          v-on:submit="submit" />
      </b-col>
  </b-row>
</template>
<script>
import MainForm from './form'
// import { mapState } from 'vuex'

export default {
  name: 'portalEdit',
  data() {
    return {}
  },
  computed: {},
  created() {
    const vm = this
    vm.$store.dispatch('portal/FETCH_ONE', vm.$route.params.id).then((res) => {
      // console.log(res)
    })
  },
  methods: {
    submit() {
      const vm = this
      vm.$store.dispatch('portal/UPDATE', vm.$route.params.id).then((res) => {
        vm.$notification.success({
          message: 'Portal Updated',
          description: 'Successfully updated.',
        })
        vm.$router.push({ path: '/portals' })
      })
    },
  },
  components: {
    MainForm,
  },
}
</script>
<style lang="scss" module>
// Import mixins
</style>
